"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Row = _interopRequireDefault(require("react-bootstrap/lib/Row"));
/** A set of columns to guide element placement
 */


var Row = function Row(_ref) {
  var children = _ref.children,
      htmlId = _ref.htmlId,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["children", "htmlId"]);
  delete props.bsClass;
  return _react["default"].createElement(_Row["default"], Object.assign({
    id: htmlId
  }, props), children);
};

Row.defaultProps = {
  componentClass: "div"
};
var _default = Row;
exports["default"] = _default;