import React from "react";
import PropTypes from "prop-types";

function formatUrlToString(string) {
  return string
    .split("-")
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join("");
}

const LayoutPage = props => {
  const component = formatUrlToString(props.match.params.component);
  const LayoutComponent = require(`./layouts/Grids/${component}`).default;

  return (
    <div className="layout">
      <header>
        <h3>
          {component}
          .js
        </h3>
        <a
          href={
            "https://ghe.coxautoinc.com/coxauto-ui/common-experience/tree/master/packages/ui/src/docs/layouts/Grids/" +
            component +
            ".js"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Github
        </a>
      </header>
      <LayoutComponent />
    </div>
  );
};

LayoutPage.propTypes = {
  match: {
    params: {
      component: PropTypes.string
    }
  }
};

export default LayoutPage;
