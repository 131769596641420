import React from "react";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";

export default function Grids84() {
  return (
    <div>
      <Grid htmlId="FluidGridExample">
        <Row>
          <Col xs={12}>
            <h4>Fluid grid</h4>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={12} md={8}>
            <code>
              &lt;
              {"Col xs={12} md={8}"} /&gt;
            </code>
          </Col>
          <Col xs={12} md={4}>
            <code>
              &lt;
              {"Col xs={12} md={4}"} /&gt;
            </code>
          </Col>
        </Row>
      </Grid>

      <Grid htmlId="FixedGridExample" fluid={false}>
        <Row>
          <Col xs={12}>
            <h4>Fixed grid</h4>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={12} md={8}>
            <code>
              &lt;
              {"Col xs={12} md={8}"} /&gt;
            </code>
          </Col>
          <Col xs={12} md={4}>
            <code>
              &lt;
              {"Col xs={12} md={4}"} /&gt;
            </code>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
