"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Grid = _interopRequireDefault(require("react-bootstrap/lib/Grid"));
/** A set of columns to guide element placement
 */


var Grid = function Grid(_ref) {
  var htmlId = _ref.htmlId,
      children = _ref.children,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["htmlId", "children"]);
  delete props.bsClass;
  return _react["default"].createElement(_Grid["default"], Object.assign({
    id: htmlId
  }, props), children);
};

Grid.defaultProps = {
  componentClass: "div",
  fluid: true
};
var _default = Grid;
exports["default"] = _default;