"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Button = _interopRequireDefault(require("react-bootstrap/lib/Button"));
/** Highlighted actions to select */


var Button = function Button(_ref) {
  var htmlId = _ref.htmlId,
      children = _ref.children,
      size = _ref.size,
      buttonStyle = _ref.buttonStyle,
      block = _ref.block,
      disabled = _ref.disabled,
      href = _ref.href,
      onClick = _ref.onClick,
      type = _ref.type,
      className = _ref.className,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["htmlId", "children", "size", "buttonStyle", "block", "disabled", "href", "onClick", "type", "className"]);
  return _react["default"].createElement(_Button["default"], Object.assign({
    id: htmlId,
    bsSize: size === "default" ? null : size,
    bsStyle: buttonStyle,
    block: block,
    disabled: disabled,
    href: href,
    onClick: onClick,
    type: type,
    className: className
  }, props), children);
};

Button.defaultProps = {
  block: false,
  buttonStyle: "default",
  size: "default"
};
var _default = Button;
exports["default"] = _default;