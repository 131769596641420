import React from "react";
import Button from "@cx/ui/Button";

function DocFrame() {
  return (
    <div className="docFrame">
      <div className="newHomepage">
        <div className="cxLogo">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 201.1 147.48"
          >
            <path
              d="M183.03 147.47H18.33A18.33 18.33 0 0 1 0 129.14V17.86A17.85 17.85 0 0 1 17.86 0h164.91a18.32 18.32 0 0 1 18.33 18.33V129.4a18.07 18.07 0 0 1-18.07 18.07"
              fillRule="evenodd"
              fill="#0ad5a7"
            />
            <path
              d="M140.24 87.46l-10.61-13.54-30.35-39.7c-1.08-1.5-.6-3.13 1.33-3.13h18.06a3.24 3.24 0 0 1 3 1.88l18.67 26.3 18.07-26.3a3.61 3.61 0 0 1 3.25-1.88h18.19c1.92 0 2.41 1.63 1.2 3.13l-29.86 39.33 30.47 39.82c1.21 1.51.73 3-1.2 3h-17.91a3.36 3.36 0 0 1-3.13-1.75zm-3.6 5.06l-15.68 22.11a3.16 3.16 0 0 1-3 1.75H99.88c-1.93 0-2.41-1.5-1.2-3l26.93-34.33zm-74 23.86c-26.68 0-43.9-16.57-43.9-42.77 0-24.25 19.52-42.52 44.26-42.52 11.64 0 19.28 2.8 25 5.73a1.74 1.74 0 0 1 .85 2.55l-5.58 11.7a1.6 1.6 0 0 1-2.43.73c-5.07-2.56-10.65-4.88-18.16-4.88-14 0-25.59 11.45-25.59 26.68 0 15.84 11.15 26.81 25.71 26.81a38.58 38.58 0 0 0 18.75-4.87c1.09-.48 1.7-.48 2.18.61l5.58 11.94a1.52 1.52 0 0 1-.85 2.32c-6.38 3.29-14.26 5.97-25.78 5.97z"
              fill="#fff"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <div className="cxGoodbyetext">
          <h1>This CX site is out of date</h1>
          <p>
            No further updates will be made to the CX site at{" "}
            <strong>
              <nobr>common-experience.firebaseapp.com</nobr>
            </strong>
            .<br />
            Go to{" "}
            <a href="https://cx.coxautoinc.com">
              https://cx.coxautoinc.com
            </a>{" "}
            for the latest resources.
          </p>
          <Button
            htmlId="ButtonDefault"
            buttonStyle="primary"
            size="lg"
            href="https://cx.coxautoinc.com"
          >
            Go to new URL
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DocFrame;
