/* eslint react/prop-types: 0 */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "prismjs/themes/prism-okaidia.css";
import "./index.scss";
import DocFrame from "./DocFrame";
import ScrollToTop from "./ScrollToTop";
import LayoutPage from "./LayoutPage";
import GoogleAnalytics from "react-ga";

GoogleAnalytics.initialize("UA-123178937-1");

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  class HOC extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const newPage = this.props.location.pathname;

      if (newPage !== prevProps.location.pathname) {
        trackPage(newPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return HOC;
};

render(
  <Router className="main-layout">
    <ScrollToTop>
      <Switch>
        <Route path="/layout/grids/:component" component={LayoutPage} />
        <Route
          path="/components/:page"
          component={
            process.env.NODE_ENV === "production"
              ? withTracker(DocFrame, {})
              : DocFrame
          }
          exact
        />
        <Route
          component={
            process.env.NODE_ENV === "production"
              ? withTracker(DocFrame, {})
              : DocFrame
          }
        />
      </Switch>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
